import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import FrontPageLink from '../components/FrontPageLink'
import Image from '../components/image'
import * as styles from './cv.module.css'

const Introduction = () => (
  <Layout img={'1'}>
    <SEO title="CV" />
    <FrontPageLink />
    <div className={styles.container}>
      <div className={styles.textContent}>
        <h2>CV</h2>
        <p>
          Yli 20 vuoden työkokemus hoitoalalta ja  yli 15 vuoden ajan työskennellyt akuuttihoitoalalla.
        </p>
        <h3>Koulutus</h3>
        <p>
          Simulaatiokouluttaja 2020
          <br /> Ammatillinen opettaja 2019
          <br /> Ensiavun ja terveystiedon kouluttaja 2019
          <br /> Ylempi ammattikorkeakoulututkinto 2016: tulevaisuuden tietojohtaminen sosiaali-ja terveysalalla
          <br /> Lääkehoitotyön erikoistumisopinnot 2007
          <br /> Sairaanhoitaja AMK 2004
          <br /> Lähihoitaja 1999
          <br /> Ylioppilas 1998
        </p>
        <h3>Työkokemus</h3>

        <p>
          Ensiapukoulutukset 2019 alkaen
          <br />
          Hoitotyön opettajan tehtävät 2019 alkaen
          <br />
          Sairaanhoitajan tehtävät vuodesta 2004:
        </p>
        <ul>
          <li>HUS päivystyspoliklinikka, sairaanhoitajan tehtävät 2017 alkaen</li>
          <li>HUS plastiikkakirurgian osasto 2016</li>
          <li>HUS päivystysosasto 2006-2016</li>
          <li>HUS verisuonikirurgian osasto 2006</li>
          <li>TYKS traumatologian osasto 2005</li>
          <li>VSSHP sisätautiosasto 2004</li>
        </ul>
      </div>
      <div className={styles.image}>
        <Image />
      </div>
    </div>
    <div>
      <h2>REFERENSSIT</h2>
      <h3 id={'ref1'}>PTMovement Oy</h3>
      <p>
        Erinomainen kouluttaja, jonka opetustyyli on mukaansatempaava ja vakaviakin asioita käydään läpi
        huumorinsävytteisesti. Erilaisia opetusmenetelmiä oli käytössä todella monipuolisesti, jolloin mielenkiinto
        aiheeseen säilyi koko kaksipäiväisen kurssin ajan!
      </p>
      <p>
        <i>- Tatu Pussila, Toimitusjohtaja, Personal Trainer</i>
      </p>
      <h3 id={'ref2'}>Kreate</h3>
      <p>
        Mukaansatempaava, erittäin mielenkiintoinen kurssi, jonka vetäjä tietää mistä puhuu! Huomaa kouluttajasta, että
        toimii päivittäin näiden asioiden parissa. Mukana sopivasti huumoria ja käytännön esimerkkejä tosielämästä!{' '}
      </p>
      <p>
        <i>- Jari Kautonen, Työturvallisuuspäällikkö</i>
      </p>
      <h3 id={'ref3'}>Miele</h3>
      <p>Erinomainen koulutus, joka voitiin rakentaa juuri meidän tarpeiden ympärille. Suosittelen. </p>
      <p>
        <i>- Kimmo Kyllönen, Head of Field Service | After Sales Business Unit</i>
      </p>
      <h3 id={'ref4'}>Stadin ammatti- ja aikuisopisto</h3>
      <p>Kouluttaja soveltuu mainiosti maahanmuuttajataustaisten nuorten ja aikuisten kanssa toimimiseen. Kouluttaja esiintyy elävästi ja huomioi kulttuuristen taustojen erilaisuudet. Opetus onnistuu selkokielellä ja kielimuurit ylittäen. </p>
      <p>
        <i>- Stadin ammatti- ja aikuisopisto</i>
      </p>
    </div>
  </Layout>
)

export default Introduction
